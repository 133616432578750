<template>
  <md-card class="md-card-stats">
    <div class="stats-header">
      <slot name="header" />
    </div>

    <div>
      <slot name="footer" />
    </div>
  </md-card>
</template>

<script>
export default {
  name: "StatsCard",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>
