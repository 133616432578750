import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
    getDashboardTitle: function () {
      let dashboardTitle = "";
      if (this.$router.currentRoute.meta.title) {
        dashboardTitle = this.$router.currentRoute.meta.title;
      } else if (this.userClaims.includes("Individuals:SuperAdmin")) {
        dashboardTitle = "SUPER ADMIN DASHBOARD";
      } else if (this.userClaims.includes("Individuals:admin")) {
        dashboardTitle = "ADMIN DASHBOARD";
      } else if (this.userClaims.includes("Individuals:doctor")) {
        dashboardTitle = "READER DASHBOARD";
      } else if (this.userClaims.includes("Individuals:technician")) {
        dashboardTitle = "TECHNICIAN DASHBOARD";
      } else if (this.userClaims.includes("Individuals:patient")) {
        dashboardTitle = "MEMBER DASHBOARD";
      } else if (this.userClaims.includes("Individuals:Provider")) {
        dashboardTitle = "Doctor DASHBOARD";
      }
      return dashboardTitle;
    },
  },
  watch: {
    "$route.params.search"(dashboardTitle) {
      if (dashboardTitle !== dashboardTitle) {
        this.dashboardTitle = this.getDashboardTitle();
      }
    },
  },
};
