<template>
  <div
    v-if="list.pages[listInput.page] && list.pages[listInput.page].isLoading"
    class="globe-activity md-layout-item md-size-100 text-center"
  >
    <spinner :diameter="30"></spinner>
  </div>
  <div
    v-else-if="
      list.pages[listInput.page] &&
      list.pages[listInput.page].items &&
      list.pages[listInput.page].items.length == 0
    "
  >
    No Results Available
  </div>
  <div v-else>
    <div class="md-content md-table-content">
      <md-table
        class="globe-activity md-table-global-sales globeactivity responsive"
        :value="
          list.pages[listInput.page] && list.pages[listInput.page].items
            ? list.pages[listInput.page].items
            : []
        "
      >
        <!-- md-fixed-header -->
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Activity">{{ item.action }}</md-table-cell>
          <md-table-cell md-label="Name">{{ item.actor }}</md-table-cell>
          <md-table-cell md-label="Locality">{{ item.locality }}</md-table-cell>
          <md-table-cell md-label="Date-Time">{{ item.date }}</md-table-cell>
        </md-table-row>
      </md-table>
      <ListFooterLayout />
    </div>
  </div>
</template>

<script>
import ListFooterLayout from "../../layouts/list-layout/ListFooterLayout.vue";
export default {
  inject: ["listInput", "list"],
  name: "GlobeActivitySlimTable",
  components: {
    ListFooterLayout,
  },
};
</script>
<style lang="scss" scoped>
.globe-activity {
  height: calc(90vh - 450px);
  overflow: auto;
}
.md-table-cell {
  //  padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.globeactivity.responsive td:nth-of-type(1):before {
  content: "Activity";
}
.globeactivity.responsive td:nth-of-type(2):before {
  content: "Name";
}
.globeactivity.responsive td:nth-of-type(3):before {
  content: "Locality";
}
.globeactivity.responsive td:nth-of-type(4):before {
  content: "Date-Time";
}
</style>
