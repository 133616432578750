<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <h3 class="md-title m-0">{{ this.getDashboardTitle }}</h3>
      </div>
    </div>
    <!-- for patient dashboard -->
    <div v-if="IsPatient">
      <HasPermission :permission="Permissions.EyeExamsRead">
        <div class="md-layout dashboard-cards">
          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-1
            "
          >
            <div @click="gotoRoute('Messages')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fa fa-envelope" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isMessagesLoading"
                    ></spinner>
                    <animated-number v-else :value="dashboardCount.messages" />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    MESSAGES
                  </div>
                </template>
              </stats-card>
            </div>
          </div>

          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-2
            "
          >
            <div @click="gotoRoute('Reports')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fa fa-eye" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isReportsLoading"
                    ></spinner>
                    <animated-number v-else :value="dashboardCount.reports" />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    EYE EXAMS
                  </div>
                </template>
              </stats-card>
            </div>
          </div>

          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-3
            "
          >
            <div @click="gotoRoute('/Referrals')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fas fa-user-md" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isReferralDoctorsCountLoading"
                    ></spinner>
                    <animated-number
                      v-else
                      :value="dashboardCount.referralDoctorsCount"
                    />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    REFERRALS
                  </div>
                </template>
              </stats-card>
            </div>
          </div>

          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-4
            "
          >
            <div @click="gotoRoute('patientHistory')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fas fa-file" />
                  <h3 class="title">
                    <animated-number :value="1" />
                    <!-- <animated-number :value="dashboardCount.reports" /> -->
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    PERSONAL RECORD
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
        </div>
      </HasPermission>
    </div>

    <!-- for Admin and reader dashboard -->
    <div v-else>
      <div class="md-layout dashboard-cards">
        <HasPermission :permission="Permissions.MessagesRead">
          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-1
            "
          >
            <div @click="gotoRoute('Messages')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fa fa-envelope" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isMessagesLoading"
                    ></spinner>
                    <animated-number v-else :value="dashboardCount.messages" />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    Messages
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
        </HasPermission>

        <HasPermission :permission="Permissions.ScreeningRead">
          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-2
            "
          >
            <div @click="gotoRoute('/globe-chek/Screenings')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fa fa-eye" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isScreeningsAvailableLoading"
                    ></spinner>
                    <animated-number
                      v-else
                      :value="dashboardCount.screeningsAvailable"
                    />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    Screenings
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
        </HasPermission>

        <HasPermission :permission="Permissions.ScreeningsonholdRead">
          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-3
            "
          >
            <div @click="gotoRoute('/globe-chek/Onhold')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fas fa-user-md" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isScreeningsOnholdLoading"
                    ></spinner>
                    <animated-number
                      v-else
                      :value="dashboardCount.screeningsOnhold"
                    />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    On-Hold
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
        </HasPermission>

        <HasPermission :permission="Permissions.ReportsRead">
          <div
            class="
              md-layout-item
              md-medium-size-25
              md-xsmall-size-50
              md-size-25
              md-theme-4
            "
          >
            <div id="test" @click="gotoRoute('/globe-chek/Reports')">
              <stats-card header-color="primary">
                <template slot="header">
                  <i class="fa fa-file" />
                  <h3 class="title">
                    <spinner
                      :diameter="25"
                      style="padding: 10px"
                      v-if="dashboardCount.isReportsLoading"
                    ></spinner>
                    <animated-number v-else :value="dashboardCount.reports" />
                  </h3>
                </template>

                <template slot="footer">
                  <div
                    class="stats"
                    style="font-weight: 400; font-size: 0.9rem"
                  >
                    Reports
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
        </HasPermission>

        <HasPermission :permission="Permissions.GlobeactivityRead">
          <div class="md-layout-item md-size-100">
            <globe-activity-slim-card>
              <template slot="header">
                <div class="card-icon">
                  <i class="fa fa-globe"></i>
                </div>
                <h4 class="title">Globe Activity</h4>
              </template>

              <template slot="content">
                <div class="md-layout">
                  <div class="md-layout-item" align="Left">
                    <GlobeActivitySlimTable />
                  </div>
                </div>
              </template>
            </globe-activity-slim-card>
          </div>
        </HasPermission>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "@/components/Globechek/Cards/StatsCard.vue";
import AnimatedNumber from "@/components/Globechek/Cards/AnimatedNumber.vue";
import GlobeActivitySlimCard from "@/components/Globechek/Cards/GlobeActivitySlimCard.vue";
import GlobeActivitySlimTable from "@/components/Globechek/Tables/GlobeActivitySlimTable.vue";
import UserTitleService from "../../services/layout-services/UserTitleService";
import { mapState } from "vuex";

export default {
  mixins: [UserTitleService],
  components: {
    StatsCard,
    AnimatedNumber,
    GlobeActivitySlimCard,
    GlobeActivitySlimTable,
  },
  computed: {
    ...mapState({
      screeningDate: (state) => state.dashboard.screeningDates,
    }),
  },
  inject: ["Permissions", "dashboardCount", "getCounts", "userClaims"],
  data() {
    return {
      IsPatient: false,
      // route: "/patientHistory/" + this.screeningDate.screeningID,
    };
  },
  created: function () {
    this.getCounts();
    if (this.userClaims.includes(this.Permissions.IsPatient)) {
      this.IsPatient = true;
    }
    // console.log(this.screeningDate.screeningID);
  },
  methods: {
    gotoRoute(r) {
      if (r == "patientHistory") {
        r += "/" + this.screeningDate.patientID;
      }
      this.$router.push("" + r);
    },
  },
};
</script>
<style scoped>
.md-card.card-dashborad {
  border-radius: 6px;
}
.dashboard-cards .md-theme-1 .md-card {
  border: 1px solid #5cb7ff;
  color: #5cb7ff;
}
.dashboard-cards .md-theme-2 .md-card {
  border: 1px solid #eb9767;
  color: #eb9767;
}
.dashboard-cards .md-theme-3 .md-card {
  border: 1px solid #d6bf5d;
  color: #d6bf5d;
}
.dashboard-cards .md-theme-4 .md-card {
  border: 1px solid #73dc63;
  color: #73dc63;
}
.dashboard-cards .md-theme-1 .md-card .title {
  color: #5cb7ff;
}
.dashboard-cards .md-theme-2 .md-card .title {
  color: #eb9767;
}
.dashboard-cards .md-theme-3 .md-card .title {
  color: #d6bf5d;
}
.dashboard-cards .md-theme-4 .md-card .title {
  color: #73dc63;
}
.dashboard-cards .md-theme-1 .md-card:hover {
  background: rgb(92 183 255 / 20%);
}
.dashboard-cards .md-theme-2 .md-card:hover {
  background: rgb(235 151 103 / 20%);
}
.dashboard-cards .md-theme-3 .md-card:hover {
  background: rgb(214 191 93 / 20%);
}
.dashboard-cards .md-theme-4 .md-card:hover {
  background: rgb(115 220 99 / 20%);
}
.card-header[data-v-2131a5ef] {
  display: flex;
  border-bottom: 1px solid #b1b1d1;
  background: #b1b1d128;
  border-radius: 6px 306 0 0;
  padding: 8px;
  align-items: center;
}
.md-card .md-card-header.md-card-header-icon i {
  font-size: 20px;
}
</style>
