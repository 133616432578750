var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list.pages[_vm.listInput.page] && _vm.list.pages[_vm.listInput.page].isLoading)?_c('div',{staticClass:"globe-activity md-layout-item md-size-100 text-center"},[_c('spinner',{attrs:{"diameter":30}})],1):(
    _vm.list.pages[_vm.listInput.page] &&
    _vm.list.pages[_vm.listInput.page].items &&
    _vm.list.pages[_vm.listInput.page].items.length == 0
  )?_c('div',[_vm._v(" No Results Available ")]):_c('div',[_c('div',{staticClass:"md-content md-table-content"},[_c('md-table',{staticClass:"globe-activity md-table-global-sales globeactivity responsive",attrs:{"value":_vm.list.pages[_vm.listInput.page] && _vm.list.pages[_vm.listInput.page].items
          ? _vm.list.pages[_vm.listInput.page].items
          : []},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
          var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Activity"}},[_vm._v(_vm._s(item.action))]),_c('md-table-cell',{attrs:{"md-label":"Name"}},[_vm._v(_vm._s(item.actor))]),_c('md-table-cell',{attrs:{"md-label":"Locality"}},[_vm._v(_vm._s(item.locality))]),_c('md-table-cell',{attrs:{"md-label":"Date-Time"}},[_vm._v(_vm._s(item.date))])],1)}}])}),_c('ListFooterLayout')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }